<template>
  <div>
    <div class="bg-white rounded shadow-sm p-3 overflow-hidden">
      <b-button
        class="float-right"
        variant="primary"
        size="sm"
        @click="fetchAllData"
      >
        <b-icon
          icon="arrow-clockwise"
          class="mr-1"
        />
        Refresh
      </b-button>
      <h2>Daftar Waiting List Pendonor - Dokter</h2>
      <b-form-group
        label="Pilih Lokasi MU : "
        label-for="lokasi-mu"
        label-cols-sm="3"
        label-align-sm="right"
        class="mr-5"
      >
        <span>Pilih Lokasi MU : </span>
        <registered-m-u-lists
          v-model="selectedMu"
          @input="handleInputMU"
        />
      </b-form-group>

      <b-table
        responsive
        :items="waitingListTable.items"
        :fields="waitingListTable.fields"
        :busy="waitingListTable.isBusy"
        show-empty
      >
        <template #cell(status)="row">
          <b-badge variant="warning">
            Proses
          </b-badge>
        </template>
        <template #cell(aksi)="row">
          <b-button
            variant="info"
            size="sm"
            :to="`/dashboards/dokter-registrasi/${row.item.registration_id}`"
          >
            <i class="ri-stethoscope-fill mr-1" />
            Detail
          </b-button>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="waitingListTable.currentPage"
        align="right"
        :total-rows="waitingListTable.total"
        :per-page="waitingListTable.perPage"
        @change="handleWaitingListPageChange"
      />
    </div>

    <div
      class="bg-white rounded shadow-sm pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden mt-5"
    >
      <h2>Daftar List Pendonor - Dokter</h2>
      <div>
        <span>Semua ( {{ table.total }} )</span>
        <div class="row no-gutters lg-w-15 float-right w-full">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
                @click="getAllDoctor"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debouncedSearch"
            />
          </b-input-group>
        </div>
      </div>

      <b-table
        responsive
        :items="table.items"
        :fields="table.fields"
        :busy="table.isBusy"
        show-empty
      >
        <template #cell(status)="row">
          <b-badge
            class="text-center"
            :variant="
              row.item.status === 'Rejected'
                ? 'danger'
                : row.item.status === 'Approved'
                  ? 'info'
                  : 'primary'
            "
          >
            {{
              row.item.status === "Rejected"
                ? "Ditolak"
                : row.item.status === "Approved"
                  ? "Diterima"
                  : "Selesai"
            }}
          </b-badge>
        </template>
        <template #cell(aksi)="row">
          <b-button
            variant="info"
            size="sm"
            :to="`/dashboards/dokter/${row.item.id}`"
          >
            <i class="ri-stethoscope-fill mr-1" />
            Detail
          </b-button>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="table.currentPage"
        align="right"
        :total-rows="table.total"
        :per-page="table.perPage"
        @change="handlePageChange"
      />
    </div>
  </div>
</template>

<script setup>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BCard,
  BIcon,
  BTable,
  BPagination,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'

import { ref, onMounted, computed } from 'vue'
import { debounce } from 'lodash'
import manageDoctorAPI from '../../../../../../api/managedoctor/manageDoctorAPI'
import RegisteredMULists from '../../../../../../components/shared/RegisteredMULists.vue'

const waitingListTable = ref({
  name: '',
  items: [],
  fields: [
    {
      key: 'status',
      label: 'Status',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Nama',
      sortable: true,
    },
    {
      key: 'address',
      label: 'Alamat',
      sortable: true,
    },
    {
      key: 'aksi',
      label: 'Aksi',
      sortable: false,
    },
  ],
  total: 0,
  currentPage: 1,
  perPage: 5,
  isBusy: false,
})

const table = ref({
  items: [],
  fields: [
    {
      key: 'status',
      label: 'Status',
      sortable: true,
    },
    {
      key: 'id',
      label: 'Id',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Nama Pendonor',
      sortable: true,
    },
    {
      key: 'collection_method',
      label: 'Metode Pengambilan Darah',
      sortable: true,
    },
    {
      key: 'note',
      label: 'Catatan',
      sortable: true,
    },
    {
      key: 'aksi',
      label: 'Aksi',
      sortable: false,
    },
  ],
  total: 0,
  currentPage: 1,
  perPage: 10,
  search: '',
  isBusy: false,
})

const selectedMu = ref(null)

const fetchAllData = async () => {
  const isWaitingListBusy = waitingListTable.value.isBusy
  const isTableBusy = table.value.isBusy
  if (isWaitingListBusy || isTableBusy) return

  try {
    waitingListTable.value.isBusy = true

    const params = {
      page: waitingListTable.value.currentPage,
      per_page: waitingListTable.value.perPage,
      search: waitingListTable.value.name,
    }

    if (selectedMu.value?.type === 'hospital' && selectedMu.value?.hospital_id) {
      params.hospital_id = selectedMu.value.hospital_id
    } else if (selectedMu.value?.type === 'blood_van' && selectedMu.value?.id) {
      params.blood_van_id = selectedMu.value.id
    }

    const { data: waitingListResponse } = await manageDoctorAPI.getWaiting(params)

    table.value.isBusy = true
    const { data: tableResponse } = await manageDoctorAPI.getAll({
      page: table.value.currentPage,
      search: table.value.search,
      per_page: table.value.perPage,
    })

    waitingListTable.value.items = waitingListResponse.data.data
    waitingListTable.value.total = waitingListResponse.data.total
    waitingListTable.value.perPage = waitingListResponse.data.per_page
    waitingListTable.value.currentPage = waitingListResponse.data.current_page

    table.value.items = tableResponse.data.data
    table.value.total = tableResponse.data.total
    table.value.perPage = tableResponse.data.per_page
    table.value.currentPage = tableResponse.data.current_page
  } catch (error) {
    console.error(error)
  } finally {
    waitingListTable.value.isBusy = false
    table.value.isBusy = false
  }
}

const debouncedSearch = debounce(async () => {
  table.value.currentPage = 1
  await fetchAllData()
}, 300)

async function handleWaitingListPageChange(newPage) {
  waitingListTable.value.currentPage = newPage
  await fetchAllData()
}

async function handlePageChange(newPage) {
  table.value.currentPage = newPage
  await fetchAllData()
}

function handleInputMU(selectedValue) {
  console.log('Selected value:', selectedValue)
  selectedMu.value = selectedValue
  console.log('selectedMu value (id):', selectedMu.value)

  handleWaitingListPageChange()
}

onMounted(async () => {
  fetchAllData()
})
</script>
